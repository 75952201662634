import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="next-topic"
export default class extends Controller {
  connect() {
    if (this.element.tagName === "FORM") {
      this.setValues();
    } else {
      throw new Error("Only input tags are supported.");
    }
  }

  setValues() {
    this.setLanguage();
    this.setLatitude();
    this.setLongitude();
    this.setPwa();
    this.setScreenHeight();
    this.setScreenWidth();
    this.setTimezone();
    this.setViewportHeight();
    this.setViewportWidth();
  }

  setInputElementValue(name, value) {
    const input = this.element.querySelector(`input[name="${name}"]`);
    if (input) {
      input.value = value;
    }
  }

  setLanguage() {
    this.setInputElementValue("next_topic[topic_log_attributes][language]", window.navigator.language);
  }

  setLatitude() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setInputElementValue("next_topic[topic_log_attributes][latitude]", position.coords.latitude);
    });
  }

  setLongitude() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setInputElementValue("next_topic[topic_log_attributes][longitude]", position.coords.longitude);
    });
  }

  setPwa() {
    this.setInputElementValue("next_topic[topic_log_attributes][pwa]", window.matchMedia("(display-mode: standalone)").matches);
  }

  setScreenHeight() {
    this.setInputElementValue("next_topic[topic_log_attributes][screen_height]", window.screen.height);
  }

  setScreenWidth() {
    this.setInputElementValue("next_topic[topic_log_attributes][screen_width]", window.screen.width);
  }

  setTimezone() {
    this.setInputElementValue("next_topic[topic_log_attributes][timezone]", Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  setViewportHeight() {
    this.setInputElementValue("next_topic[topic_log_attributes][viewport_height]", window.innerHeight);
  }

  setViewportWidth() {
    this.setInputElementValue("next_topic[topic_log_attributes][viewport_width]", window.innerWidth);
  }
}
