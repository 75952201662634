import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

import { noop } from "../helpers/noop";

// Connects to data-controller="flash"
export default class extends Controller {
  timeout = null;

  connect() {
    this.open().then(noop);
  }

  disconnect() {
    this.resetTimeout();
  }

  async open() {
    await enter(this.element);
    this.timeout = window.setTimeout(() => this.close(), 5000);
  }

  async close() {
    this.resetTimeout();
    await leave(this.element);
    this.element.remove();
  }

  resetTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
